import React from 'react';
import styles from "../Templates/SectionContainer.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faHeart } from '@fortawesome/free-solid-svg-icons';
import helmets from "../assets/TheCreatorWeb.mp4";

const SectionContainer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.GridLeftMain}>
                <h2 className={styles.titleHelmet}>Golden Helmets Series</h2>
                    <video
                        src={helmets}
                        autoPlay
                        muted
                        loop
                        playsInline
                        className={styles.helmetVideo}
                    />
                </div>

                <div className={styles.GridRightMain}>
                    <h2 className={styles.titleHelmetDesktop} >Golden Helmets Series</h2>
                    <p>Discover our limited series of <strong>50 Golden Helmets</strong>,
                        designed not only to stand out but to enrich your
                        experience within the ENO universe. Each helmet is a
                        master key to exclusive benefits.
                    </p>
                </div>
            </div>

            {/* Benefits Grid */}
            <div className={styles.benefitsGrid}>
                {/* Early Access to the ENOVERSE */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Early Access to the ENOVERSE</h3>
                        <p>Be one of the first to explore our private metaverse.</p>
                    </div>
                </div>

                {/* Limited Edition Merchandise */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Limited Edition Merchandise</h3>
                        <p>Acquire collectible limited edition items.</p>
                    </div>
                </div>

                {/* Priority Access */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Priority Access</h3>
                        <p>Enjoy the opportunity to preview new projects, getting a glimpse of ENO’s innovation.</p>
                    </div>
                </div>

                {/* Private Digital Events */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Private Digital Events</h3>
                        <p>Gain access to special gatherings where ENO’s future takes shape.</p>
                    </div>
                </div>

                {/* Private Airdrops */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Private Airdrops</h3>
                        <p>Participate in our first airdrop in November, designed exclusively for you.</p>
                    </div>
                </div>

                {/* Limited Collection NFTs */}
                <div className={styles.benefitItem}>
                    <div className={styles.GridLeft}>
                        <FontAwesomeIcon icon={faHeart} className={styles.heartIcon} />
                    </div>
                    <div className={styles.GridRight}>
                        <h3>Limited Collection NFTs</h3>
                        <p>Private airdrops as a symbol of your contribution to the ecosystem.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionContainer;
