import React from 'react'
/* ============ FONT A W E S O M E ============ */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faLinkedinIn,
  faXTwitter,
  faInstagram,
  faDiscord,
  faTelegram,
  faRedditAlien,
} from "@fortawesome/free-brands-svg-icons";

import enologo from '../assets/ENOLogo.webp';
import enologoNegativo from "../assets/EnoLogoNegativo.webp";
import './Footer.css';

function Footer() {
  return (

    <div className="footer__content">
      <div className="footer__left">
        <div className="logo">
          <img src={enologoNegativo} alt="ENO Logo" className='enologo' />
          <img src={enologo} alt="ENO Logo" className='enologoNegativo' />
          <span className='span__footer'>One Network, Infinite Possibilities</span>
          <div className="global__icons">
            <a href="https://t.me/ENO_network" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTelegram} />
            </a>
            <a href="https://discord.com/invite/3eZewHzJU5"rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
            <a href="https://x.com/ENOnetwork" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://linkedin.com/company/enonetwork" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="https://www.youtube.com/@eno.network" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.instagram.com/enonetwork/" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.facebook.com/ENOtoken" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer__right">
        {/* <h2 className="span__section">Join Our Newsletter</h2>
        <form
          action="https://enowinesocialclub.activehosted.com/proc.php"
          method="POST"
          noValidate
          className="form__contact-form"
        >
          <input type="hidden" name="u" value="21" />
          <input type="hidden" name="f" value="21" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <input
            type="hidden"
            name="or"
            value="7522c8bdac19880a4c759091b50d5448"
            className="input__contact-form"
          />
          <div className="data__contact-form">
            <input
              type="text"
              id="email"
              name="email"
              required
              className="inputemail"
              placeholder="email@example.com"
            />
            <button type="submit" className="submit__contact-form">
              Subscribe
            </button>
          </div>
        </form> */}

        <div className="social__links">
          <div className="social__footer">
            <div className="socials">
              <p className="footer__link">Support</p>
              <a
                className="name__link"
                href="https://discord.com/invite/3eZewHzJU5"
                rel="noreferrer"
              >
                Discord
              </a>
            </div>
            <div className="socials">
              <p className="footer__link">Learn</p>
              <a
                className="name__link"
                href="https://docs.enotoken.io/"
                rel="noreferrer"
              >
                Whitepaper
              </a>
            </div>
            <div className="socials">
              <p className="footer__link">News</p>
              <a
                className="name__link"
                href="https://blog.enotoken.io"
                rel="noreferrer"
              >
                Blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer