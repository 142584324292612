/* config.js */

import { createWalletClient, custom } from "viem";
import { sepolia } from "viem/chains";
import masterChefAbi from "./masterchef.json";
import tokenAbi from "./erc20abi.json";
import enoStakingAbi from './stakinglpABI.json'; // Ajusta la ruta según corresponda
import { ethers } from "ethers";

const masterchefAddr = '0xfcd48c0449dF166FF4C943238d2a7790e9d3126B';
const tokenAddress = '0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F';
const usdt = '0x3fC3088810463C556A50c5fBC9dbC0267e2D7fDF';
const lp = '0x97C991cBC7F4C1A1351FE61B4F52A214A3334D4d';
const enoStakingAddress = '0x7b40352915040444429aD1fEF712f9B1F75bB743';


//
// STAKING NORMAL
//

const roundDown = (value, decimals) => {
  const factor = Math.pow(10, decimals);
  return (Math.floor(parseFloat(value) * factor) / factor).toString();
}

const web3Provider = async () => {
  const [account] = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const client = createWalletClient({
    account,
    chain: sepolia,
    transport: custom(window.ethereum),
  });
  return client;
};

const convertToEth = (value, decimals) => {
  const etherValue = ethers.utils.formatEther(value);
  return roundDown(etherValue, decimals);
}


export const fetchTokenBalance = async (tokenaddress, userwallet) => {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  const web3connection = await connectWallet();
  const tokencontract = new ethers.Contract(tokenaddress, tokenAbi, web3connection.signer);
  let poolBalance = await tokencontract.balanceOf(masterchefAddr);
  let pool = await convertToEth(poolBalance, 2);

  let userBalance = await tokencontract.balanceOf(userwallet);
  let user = await convertToEth(userBalance, 2);
  return {pool, user};
}

export const getPoolDetails = async () => {
  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return [];
  }

  let web3connection = await connectWallet();
  if (!web3connection) return [];

  let userwallet = web3connection.connection.account.address;
  let masterchef = web3connection.masterchef;
  const poolLength = Number((await masterchef.poolLength()).toString());
  const tokensPorBloque = Number(ethers.utils.formatEther(await masterchef.tokenPerBlock()));
  const totalAllocPoint = Number((await masterchef.totalAllocation()).toString());
  const bloquesPorAño = 10512000; // Ajusta esto según la cadena de bloques que estés utilizando

  let poolArray = [];

  for (let i = 0; i < poolLength; i++) {
    const poolInfo = await masterchef.poolInfo(i);
    const tokenAddress = poolInfo.lpToken;
    const allocPointDelPool = Number(poolInfo.allocPoint.toString());

    // Crear una instancia del contrato ERC20 usando la dirección del token LP
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, web3connection.signer);

    // Usar esta instancia para llamar a balanceOf
    const poolBalance = await tokenContract.balanceOf(masterchef.address);
    const totalTokensApostadosEnElPool = Number(ethers.utils.formatEther(poolBalance));

    let tokenbalances = await fetchTokenBalance(tokenAddress, userwallet);
    if (!tokenbalances) continue; // Si tokenbalances es null, saltar a la siguiente iteración

    let userStakedArray = await masterchef.userInfo(i, userwallet);
    let userRewardRaw = (await masterchef.pendingReward(i, userwallet)).toString();
    let bonus = (await masterchef.BONUS_MULTIPLIER()).toString();

    let userReward = await convertToEth(userRewardRaw, 2); // Usando 2 decimales para el redondeo
    let userStaked = await convertToEth(userStakedArray.amount.toString(), 2); // Usando 2 decimales para el redondeo

    // Cálculo del APR
    const APR = (tokensPorBloque * bloquesPorAño * allocPointDelPool / totalAllocPoint * bonus) / totalTokensApostadosEnElPool * 100;

    let poolstats = {
      "totalstaked": tokenbalances.pool,
      "apr": APR.toFixed(2), // Usamos APR calculado
      "userstaked": userStaked,
      "reward": userReward,
      "multiplier": bonus,
      "userbalance": tokenbalances.user,
      "tokenaddr": tokenAddress
    };

    poolArray.push(poolstats);
  }

  return poolArray;
};


export const action = async (i, amount, tokenaddress, action) => {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  try {
    const web3connection = await connectWallet();
    const signer = web3connection.signer;
    const masterchef = web3connection.masterchef;
    const masterchefAddr = web3connection.masterchefAddr;
    const tokencontract = new ethers.Contract(tokenaddress, tokenAbi, signer);

    // Convierte el monto a Wei
    let amountToWei = ethers.utils.parseEther(amount.toString());

    if (action == "stake") {
      // Verifica cuánto token ha sido ya aprobado para el contrato MasterChef
      const allowance = await tokencontract.allowance(signer.getAddress(), masterchefAddr);
      const maxUint256 = ethers.constants.MaxUint256;

      if (allowance.lt(amountToWei)) {
        // Si el allowance es menor que el monto a apostar, pide aprobación
         ("Solicitando aprobación...");
        let approveTx = await tokencontract.approve(masterchefAddr, maxUint256);
        await approveTx.wait();
         ("Aprobación concedida.");
      }

       ("Realizando stake...");
      let stakeTx = await masterchef.stake(i, amountToWei);
      await stakeTx.wait();
       ("Stake completado.");
      return true;
    } else if (action == "unstake") {
       ("Realizando unstake...");
      let unstakeTx = await masterchef.unstake(i, amountToWei);
      await unstakeTx.wait();
       ("Unstake completado.");
      return true;
    }
  } catch (error) {
    console.error("Ocurrió un error en la acción:", error);
    return false;
  }
};

export const autoCompound = async (pid) => {
  try {
    let web3connection = await connectWallet();
    let masterchef = web3connection.masterchef;
    let signer = web3connection.signer;

    // Obtén la dirección del usuario actual
    const userAddress = await signer.getAddress();

    // Obtiene el tokenAddress desde poolInfo ya que necesitamos interactuar con el contrato del token
    const poolInfo = await masterchef.poolInfo(pid);
    const tokenAddress = poolInfo.lpToken;
    const tokencontract = new ethers.Contract(tokenAddress, tokenAbi, signer);

    // Obtiene la cantidad de rewards pendientes que el usuario tiene para hacer auto-compound
    const pendingRewards = await masterchef.pendingReward(pid, userAddress);
    const amountToWei = pendingRewards; // Asume que pendingReward ya está en Wei

    // Verifica cuánto token ha sido ya aprobado para el contrato MasterChef
    const allowance = await tokencontract.allowance(userAddress, masterchef.address);
    const maxUint256 = ethers.constants.MaxUint256;

    if (allowance.lt(amountToWei)) {
      // Si el allowance es menor que el monto de rewards pendiente, solicita aprobación para una cantidad mayor
       ("Solicitando aprobación para rewards pendientes...");
      let approveTx = await tokencontract.approve(masterchef.address, maxUint256);
      await approveTx.wait();
       ("Aprobación concedida para rewards pendientes.");
    }

    // Ahora que tenemos la aprobación, podemos proceder con el auto-compound
     ("Realizando auto-compound...");
    let tx = await masterchef.autoCompound(pid);
    await tx.wait();
     ("Auto-compound completado con éxito.");
    return true;
  } catch (error) {
    console.error("Ocurrió un error en el auto-compound:", error);
    return false;
  }
}

export const withdrawToken = async (pid) => {
  try {
    let web3connection = await connectWallet();
    let masterchef = web3connection.masterchef;
    let signer = web3connection.signer;

    // Obtén la dirección del usuario actual
    const userAddress = await signer.getAddress();

    // Obtiene el tokenAddress desde poolInfo ya que necesitamos interactuar con el contrato del token
    const poolInfo = await masterchef.poolInfo(pid);
    const tokenAddress = poolInfo.lpToken;
    const tokencontract = new ethers.Contract(tokenAddress, tokenAbi, signer);

    // Ahora que tenemos la aprobación, podemos proceder con el withdraw
     ("Realizando withdraw...");
    let tx = await masterchef.withdrawToken(pid);
    await tx.wait();
     ("Withdraw completado con éxito.");
    return true;
  } catch (error) {
    console.error("Ocurrió un error en el Withdraw:", error);
    return false;
  }
}



export const setMaxAmount = async (inputId, tokenAddress) => {
  // Asegurándonos de que tenemos una conexión y un signer válidos.
  const web3connection = await connectWallet();
  const signer = web3connection.signer;

  // Creando una instancia del contrato del token usando la dirección proporcionada y el signer.
  const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

  try {
    // Obtener la dirección del usuario conectado.
    const userAddress = await signer.getAddress();

    // Consultar el saldo del token para el usuario.
    const balanceWei = await tokenContract.balanceOf(userAddress);

    // Convertir el saldo de Wei a Ether (o la unidad principal del token) y redondear hacia abajo si es necesario.
    const balanceEther = ethers.utils.formatEther(balanceWei);

    // Asignar el saldo obtenido al input correspondiente.
    document.getElementById(inputId).value = balanceEther;
  } catch (error) {
    console.error("Error al obtener el saldo máximo:", error);
  }
}


export const setMaxAmountUnstake = async (inputId, pid) => {
  // Asegurándonos de que tenemos una conexión válida y el contrato de MasterChef.
  const web3connection = await connectWallet();
  const signer = web3connection.signer;
  const masterchef = web3connection.masterchef;

  try {
    // Obtener la dirección del usuario conectado.
    const userAddress = await signer.getAddress();

    // Consultar el saldo staked para el usuario en el pool con índice `pid`.
    const userInfo = await masterchef.userInfo(pid, userAddress);
    const stakedWei = userInfo.amount;

    // Convertir el saldo de Wei a Ether (o la unidad principal del token) y redondear hacia abajo si es necesario.
    const stakedEther = ethers.utils.formatEther(stakedWei);

    // Asignar el saldo staked obtenido al input correspondiente.
    document.getElementById(inputId).value = stakedEther;
  } catch (error) {
    console.error("Error al obtener el saldo staked máximo:", error);
  }
}

export const mintENO = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const token = new ethers.Contract(tokenAddress, tokenAbi, signer);

  try {
    const fixedAmount = "1000"; // El monto fijo de ENO a mintear
    const amountToWei = ethers.utils.parseEther(fixedAmount);

    // Ejecutar la función mint. Asegúrate de que el contrato del token tenga una función mint accesible
    const tx = await token.mint(signer.getAddress(), amountToWei);
    await tx.wait();
     (`Minted ${fixedAmount} ENO tokens successfully.`);
    return true;
  } catch (error) {
    console.error("Error minting ENO tokens:", error);
    return false;
  }
};

async function connectStaking() {
  const web3connection = await connectWallet(); // Reutiliza la conexión existente
  const signer = web3connection.signer;
  const enoStaking = new ethers.Contract(enoStakingAddress, enoStakingAbi, signer);
  return { ...web3connection, enoStaking };
}

//
// STAKING LP
//

// Convierte LP Amount a Wei para evitar errores de conversión en las llamadas al contrato
function parseLpAmountToWei(lpAmount) {
  return ethers.utils.parseEther(lpAmount.toString());
}

/**
* Realiza el staking de tokens LP.
* @param {string} lpAmount - Cantidad de tokens LP a hacer staking, como string.
* @param {number} months - Duración del staking en meses.
*/
export async function stakeTokens(lpAmount, months) {
  try {
    const { enoStaking, signer } = await connectStaking();

    // Convertir lpAmount a Wei y mostrar en consola
    const lpAmountInWei = parseLpAmountToWei(lpAmount);
     (`Staking lpAmount in Wei: ${lpAmountInWei}`);
     (`Months: ${months}`);

    // Aquí asumimos que tienes el address del token LP que vas a stakear,
    // y que es el mismo que el stakingToken del contrato ENOStaking.
    // Si no es así, necesitarás ajustar esto para obtener la dirección correcta.
    const tokenLPAddress = lp; // Asegúrate de reemplazar esto con la dirección correcta del token LP
    const tokenLPContract = new ethers.Contract(tokenLPAddress, tokenAbi, signer);

    // Verificar el allowance actual
    const currentAllowance = await tokenLPContract.allowance(await signer.getAddress(), enoStaking.address);
    if (currentAllowance.lt(lpAmountInWei)) {
       ("Solicitando aprobación para tokens LP...");
      const approveTx = await tokenLPContract.approve(enoStaking.address, ethers.constants.MaxUint256);
      await approveTx.wait();
       ("Aprobación concedida para tokens LP.");
    }

    // Ahora que el contrato tiene permiso, proceder con el staking
    const tx = await enoStaking.stake(lpAmountInWei, months);
    await tx.wait();
     ('Staking realizado con éxito');
    return true; // Asegúrate de devolver true
  } catch (error) {
    console.error('Error al realizar el staking:', error);
    return false; // Devuelve false si hay un error
  }
}



/**
* Retira el staking basado en el índice del staking.
* @param {number} stakeIndex - Índice del staking a retirar.
*/
export async function withdrawStake(stakeIndex) {
  const { enoStaking } = await connectStaking();
  
  try {
      const tx = await enoStaking.withdraw(stakeIndex);
      const receipt = await tx.wait();
       ('Retiro de staking realizado con éxito', receipt);
      // Actualizar UI aquí si es necesario
  } catch (error) {
      console.error('Error al retirar el staking:', error);
      // Manejo de errores en la UI
  }
}

/**
* Consulta los stakes de un usuario específico.
* @param {string} userAddress - Dirección del usuario a consultar.
*/
export async function getUserStakes(userAddress) {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  const { enoStaking } = await connectStaking();
  
  try {
      const stakes = await enoStaking.getStakes(userAddress);
       ('Stakes del usuario obtenidos con éxito', stakes);
      return stakes; // Podrías necesitar procesar este array antes de devolverlo
  } catch (error) {
      console.error('Error al obtener los stakes del usuario:', error);
      return []; // Devuelve un array vacío o maneja el error como prefieras
  }
}

export const fetchUserStakes = async (userAddress) => {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  try {
    const { enoStaking } = await connectStaking();
    const stakes = await enoStaking.getStakes(userAddress);
     (stakes);
    return stakes;
  } catch (error) {
    console.error('Error fetching user stakes:', error);
    return [];
  }
};

export const fetchMultipliers = async () => {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  try {
    const { enoStaking } = await connectStaking(); // Conecta con tu contrato de staking
    const months = [1, 3, 6, 12, 24, 48]; // Define los meses que te interesan

    let multipliers = {};

    for (const month of months) {
      const multiplier = await enoStaking.multiplierMapping(month); // Usar directamente el mapping si es público o la función getter adecuada
      multipliers[month] = multiplier.toString(); // Ajusta según sea necesario
    }

    return multipliers;
  } catch (error) {
    console.error("Error al obtener los multiplicadores:", error);
    return {};
  }
};

// Esta es una implementación de ejemplo para obtener el saldo de tokens LP
export const fetchLpTokenBalance = async () => {

  const isCorrectNetwork = await checkCorrectNetwork();
  if (!isCorrectNetwork) {
     ('Incorrect network, please switch to Arbitrum.');
    return null;
  }

  const { signer } = await connectWallet();
  const tokenLPContract = new ethers.Contract(lp, tokenAbi, signer);
  const address = await signer.getAddress();
  const balance = await tokenLPContract.balanceOf(address);
  return ethers.utils.formatEther(balance); // Convierte el saldo de Wei a Ether
};


// VERIFICAR RED ARBITRUM
export const checkCorrectNetwork = async () => {
  const correctChainId = '0xa4b1'; // Arbitrum One
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  return chainId === correctChainId;
};

export async function connectWallet() {
  try {
    const isCorrectNetwork = await checkCorrectNetwork();
    if (!isCorrectNetwork) {
       ('Incorrect network, please switch to Arbitrum.');
      return null;
    }

    const connection = await web3Provider();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const masterchef = new ethers.Contract(masterchefAddr, masterChefAbi, signer);
    const enoStaking = new ethers.Contract(enoStakingAddress, enoStakingAbi, signer);
    return { connection, signer, masterchef, masterchefAddr, enoStaking };
  } catch (error) {
    console.error("Error en connectWallet:", error);
    return null;
  }
}