import React, { useState } from 'react';
import "./Newsletter.css";
import ImageNewsletter from "../assets/Newsletter.webp";

const Newsletter = () => {
    return (
        <>
            <div className='newsletter'>
                <div className="newsletter-container">
                    <div className="newsletter-left">
                        <img src={ImageNewsletter} alt="" />
                    </div>
                    <div className="newsletter-right">
                        <h2>Stay Update With ENO!</h2>
                        <p>
                            Subscribe to our Newsletter and never miss our latest news,esclusive events, launches, and updates from the ENO ecosystem.
                        </p>
                        <div className='form-container'>
                            <form
                                action="https://enowinesocialclub.activehosted.com/proc.php"
                                method="POST"
                                noValidate
                                className="form__contact"
                            >
                                <input type="hidden" name="u" value="21" />
                                <input type="hidden" name="f" value="21" />
                                <input type="hidden" name="s" />
                                <input type="hidden" name="c" value="0" />
                                <input type="hidden" name="m" value="0" />
                                <input type="hidden" name="act" value="sub" />
                                <input type="hidden" name="v" value="2" />
                                <input
                                    type="hidden"
                                    name="or"
                                    value="7522c8bdac19880a4c759091b50d5448"
                                    className="input__contact-form"
                                />
                                <div className="data__contact-form">
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        required
                                        className="inputemail"
                                        placeholder="email@example.com"
                                    />
                                    <button type="submit" className="submit__contact">
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                        <span>We promise not to spam you and to respect your privacy!</span>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Newsletter;
