import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Launchpad from './components/NFTPurchase';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Newsletter from './Templates/Newsletter';
import BackToTopButton from './Templates/TopButton';
import BridgeComponent from './components/BridgeComponent';
import MyBadges from './components/MyBadges';
import MintBadge from './components/MintBadge';
import BadgeDetailView from './components/BadgeDetailView';
import MyNFT from './components/MyNFT';
import NFTPurchaseDetails from './components/NFTPurchaseDetails';
import NFTDetailView from './components/NFTDetailView';
import Staking from "./components/Staking";
import sectionContainer from './Templates/SectionContent';
// import ChatWidget from "./Templates/chatWidget";
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  const [headerVisible, setHeaderVisible] = useState(true);
  const [footerVisible, setFooterVisible] = useState(true);
  const [navBarVisible, setNavBarVisible] = useState(true);

  return (
    <ChakraProvider>
      <HelmetProvider>
        <Router>
          {headerVisible && <Header />}
          <Routes>
            <Route path="/" element={<Navigate to="/launchpad" replace />} />
            <Route path="/launchpad" element={<Launchpad />} />
            <Route path="/staking" element={<Staking />} />
            <Route path="/bridge" element={<BridgeComponent />} />
            <Route path="/newsletter" element={<Newsletter />} /> 
            <Route path="/my-badges" element={<MyBadges />} />
            <Route path="/mint-badges" element={<MintBadge />} />
            <Route path="/badges" element={<MyBadges />} />
            <Route path="/badge-detail/:badgeId" element={
              <BadgeDetailView
                setHeaderVisible={setHeaderVisible}
                setFooterVisible={setFooterVisible}
                setNavBarVisible={setNavBarVisible}
              />} />
            <Route path="/my-nft" element={<MyNFT />} />
            <Route path="/nft/:contractAddress" element={
              <NFTPurchaseDetails
                setHeaderVisible={setHeaderVisible}
                setFooterVisible={setFooterVisible}
                setNavBarVisible={setNavBarVisible}
              />} />
            <Route path="/nft-detail/:nftId" element={
              <NFTDetailView
                setHeaderVisible={setHeaderVisible}
                setFooterVisible={setFooterVisible}
                setNavBarVisible={setNavBarVisible}
              />} />
          </Routes>
          {/* ChatWidget se renderiza en todas las páginas */}
          {/* <ChatWidget apiUrl="https://tu-api-url.com/process" /> */}
          {navBarVisible && <NavBar />}
          <BackToTopButton />
          {footerVisible && <Footer />}
        </Router>
      </HelmetProvider>
    </ChakraProvider>
  );
}

export default App;