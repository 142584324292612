import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import NFTCard from './NFTCard';
import erc721ABI from '../ABIs/newBadgeENOABI.json';
import { useNetworkSwitcher, chain } from '../hooks/useNetworkSwitcher';
import useMetaMaskConnector from '../hooks/useMetaMaskConnector';
import { useToast, Spinner, Flex, Button } from '@chakra-ui/react';
import EnoBadges from "../Templates/BadgesNewsletter";
import { Link } from 'react-router-dom';
import './MyBadges.css';
import './globalStyles.css';

import Accordion from "../Templates/Accordion";
import Newsletter from "../Templates/Newsletter";
import LatestPosts from '../Templates/LatestPosts';

const nftContractsMainnet = [
  '0xCc83632FbEd7c1580f6fcF07E38423b70c527BC9',
  '0x2A06B2c0999Af12C251c55D6E2c67330AeAb3C86',
  '0xD6C9365273539C7722EAb3BAC3D76dD3b23e6Ff3',
  '0x8cDff0DF63C816df0d1BbeC7f9e7771915311EDf',
  '0x3B70F7347Ed816CDE7A5B25c5AA9BdDE753e3966',
  '0x281d59301C137E25150139da5BE433D15e8e732F',
  '0xa1b79845a7a704D0877C8a4A80072F8ce422104b',
];

const MyBadges = () => {
  const { currentNetwork, changeNetwork, error } = useNetworkSwitcher();
  const { isConnected, connectMetaMask, message } = useMetaMaskConnector();
  const toast = useToast();
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNFTs = useCallback(async () => {
    if (!isConnected || currentNetwork !== chain) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const nftPromises = nftContractsMainnet.map(async (contractAddress) => {
      const contract = new ethers.Contract(contractAddress, erc721ABI, signer);
      const balance = await contract.balanceOf(await signer.getAddress());
      const nftTokenIds = [];

      for (let i = 0; i < balance.toNumber(); i++) {
        const tokenId = await contract.tokenOfOwnerByIndex(await signer.getAddress(), i);
        const tokenURI = await contract.tokenURI(tokenId);
        const response = await fetch(tokenURI);
        const metadata = await response.json();

        nftTokenIds.push({
          contractAddress,
          tokenId: tokenId.toNumber(),
          title: metadata.name,
          videoUrl: metadata.image || '',
          description: metadata.description || '',
        });
      }

      return nftTokenIds;
    });

    const nftResults = await Promise.all(nftPromises);
     (`NFT Results: `, nftResults);
    setNfts(nftResults.flat());
    setIsLoading(false);
  }, [isConnected, currentNetwork]);

  useEffect(() => {
    fetchNFTs();
  }, [fetchNFTs]);

  const renderConnectMessage = () => {
    if (!isConnected && currentNetwork !== chain) {
      return 'Please connect your wallet and switch to the correct network.';
    } else if (!isConnected) {
      return 'Please connect your wallet.';
    } else if (currentNetwork !== chain) {
      return 'Please switch to the correct network.';
    }
    return '';
  };

  const renderConnectButtonLabel = () => {
    if (!isConnected) {
      return 'Connect Wallet';
    } else if (currentNetwork !== chain) {
      return 'Change Network';
    }
    return '';
  };

  const handleConnect = async () => {
    if (!isConnected) {
      await connectMetaMask();
    } else if (currentNetwork !== chain) {
      await changeNetwork();
    }
  };

  const isMetaMaskInstalled = () => {
    return typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';
  };

  if (!isMetaMaskInstalled()) {
    return (
      <div className="install-metamask-container">
        <button as="a" href="https://metamask.io/download.html" colorScheme="teal" size="lg" className='connect__container-install'>
          Install Wallet
        </button>
        <p className="install-message">Please install a web3 compatible wallet to proceed.</p>
      </div>
    );
  }

  if (!isConnected || currentNetwork !== chain) {
    return (
      <div className="connect-container">
        <Button onClick={handleConnect} colorScheme="teal" size="lg">
          {renderConnectButtonLabel()}
        </Button>
        <p className="connect-message">{renderConnectMessage()}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="container">
        {/* <h2 className="hero__title">Loading...</h2>
        <Spinner size="xl" /> */}
      </div>
    );
  }

  if (nfts.length === 0) {
    return (
      <div className="container-badge">
        <h2 className="hero__title-badge">No Badges Found</h2>
        <div className="launchpad-container">
          <div className="launchpad__container-btns">
            <Link to="/mint-badges">
              <button className="switch-button">Mint Badges</button>
            </Link>
            <Link to="/my-badges">
              <button className="switch-button-active">My Badges</button>
            </Link>
          </div>
        </div>
        <br />
        <p>You don't have any badges yet. Interact with the ENO ecosystem and get your ENO badges.</p>
      </div>
    );
  }

  return (
    <div className="container">
      <EnoBadges showSection="badges-alternative" />      
      <Flex justifyContent="center" width="100%" alignItems="center">
        <Flex alignItems="center">
          <h2 className="hero__title white">My NFT Badges</h2>
        </Flex>
      </Flex>

      <div className="launchpad-container">
        <div className="launchpad__container-btns">
          <Link to="/mint-badges">
            <button className="switch-button">Mint Badges</button>
          </Link>
          <Link to="/my-badges">
            <button className="switch-button-active">My Badges</button>
          </Link>
        </div>
      </div>

      <div className="badge-grid-list">
        {nfts.map((nft, index) => (
          <NFTCard key={index} nft={nft} />
        ))}
      </div>
      <Accordion showSection="accordeon-3"/>
      <LatestPosts showSection="lastpost-2"/>
      <Newsletter />
    </div>
  );
};

export default MyBadges;
