import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import './BadgeMintCard.css';

const BadgeMintCard = ({ badge, badgeData, onMintNowClick, abi }) => {
  const [saleStartTime, setSaleStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});

  const initializeProvider = () => {
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      return new ethers.providers.Web3Provider(window.ethereum);
    }
    return null;
  };

  const provider = initializeProvider();

  const isUsingNewABI = abi.some((item) => item.name === "saleStartTime"); // Verifica si el ABI incluye el método saleStartTime

  const fetchSaleStartTime = useCallback(async () => {
    if (!provider || !isUsingNewABI) return; // Si no es el ABI nuevo, no hacer la solicitud

    try {
      const badgeContract = new ethers.Contract(badge.contractAddress, abi, provider);
      const saleStartTimeNumber = await badgeContract.saleStartTime();
      setSaleStartTime(saleStartTimeNumber.toNumber());
    } catch (error) {
      console.error('Error fetching sale start time:', error);
    }
  }, [badge.contractAddress, provider, abi, isUsingNewABI]);

  useEffect(() => {
    if (isUsingNewABI) {
      fetchSaleStartTime(); // Solo solicita el saleStartTime si es el ABI nuevo
    }
  }, [fetchSaleStartTime, isUsingNewABI]);

  useEffect(() => {
    const timerId = setInterval(() => {
      if (saleStartTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (saleStartTime > currentTime) {
          setTimeLeft(calculateTimeLeft(saleStartTime));
        } else {
          setTimeLeft({});
        }
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [saleStartTime]);

  const calculateTimeLeft = (startTime) => {
    const now = Math.floor(Date.now() / 1000);
    const difference = startTime - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (60 * 60 * 24)),
        hours: Math.floor((difference % (60 * 60 * 24)) / (60 * 60)),
        minutes: Math.floor((difference % (60 * 60)) / 60),
        seconds: Math.floor(difference % 60),
      };
    } else {
      return {};
    }
  };

  const isFullyMinted = badgeData.totalSupply >= badgeData.maxSupply;

  return (
    <div className="badge-mint-card">
      <div className={`nft-video-container ${Object.keys(timeLeft).length !== 0 ? 'blur' : ''}`}>
        <img src={badge.image} alt={`${badge.name} image`} className="nft-preview" />
        {Object.keys(timeLeft).length !== 0 && (
          <div className="countdown-overlay">
            <p className='countdown-NFT'>COUNTDOWN</p>
            <div className="countdown-timer">
              {timeLeft.days} &nbsp;&nbsp; {timeLeft.hours} &nbsp;&nbsp;  {timeLeft.minutes} &nbsp;&nbsp;  {timeLeft.seconds}
            </div>
            <div className="countdown-timer2">
              days  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              hours &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              minutes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; seconds            
            </div>
          </div>
        )}
      </div>
      <div className="mint-info">
        <p className='title__badge'>{badge.name}</p>
        <p className='subtitle__badge'>{badgeData.totalSupply} of {badgeData.maxSupply} Minted</p>
        {badge.description && <p className="badge__description">{badge.description}</p>}
      </div>
      <button 
        onClick={() => onMintNowClick(badge.contractAddress)}
        className="hero__btn-mint color-1"
        disabled={isFullyMinted || Object.keys(timeLeft).length !== 0}
      >
        {isFullyMinted ? 'Fully Claimed' : (Object.keys(timeLeft).length !== 0 ? 'Coming Soon' : 'Mint')}
      </button>
    </div>
  );
};

export default BadgeMintCard;
