import React from 'react';
import backBadges from "../assets/BackBadges.mp4";
import BadgeCover from "../assets/BadgesNewsletter.webp";
import NFTBlanco from "../assets/NFTBlanco.mp4";
import styles from "../Templates/BadgesNewsletter.module.css";

// Versión Anterior
import badgesNFTs from '../assets/BackDeskNFT.mp4';
function Badges({ showSection }) {
  return (
    <>

      {/* Para pantallas o vistas como BADGES y NFT's donde el fondo
      no cambia de color, se utilizan los "alternative", cuando la vista
      tiene un fondo blanco en responsivo se usan los que no 
      tienen alternative */}

      {showSection === "badges" && (
        /* ======= Badge Section ======= */
        <section className={styles.badge__video}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={backBadges}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} />
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleBadge}>
                What are ENO Badges?
              </h2>
              <span className={styles.span__subtitleBadge}>
                ENO‘s Badges are NFTs that verify your <br />
                participation in an activity within our social ecosystem.
              </span>

            </div>
          </div>
        </section>
      )}

      {showSection === "badges-alternative" && (
        /* ======= Badge Section ======= */
        <section className={styles.badge__video__alt}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={backBadges}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} />
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleBadge}>
                What are ENO Badges?
              </h2>
              <span className={styles.span__subtitleBadge}>
                ENO‘s Badges are NFTs that verify your <br />
                participation in an activity within our social ecosystem.
              </span>

            </div>
          </div>
        </section>
      )}

      {showSection === "badgesDetailView" && (
        /* ======= Badge Section ======= */
        <section className={styles.badge__videoDetailView}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={backBadges}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} />
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleBadge}>
                What are ENO Badges?
              </h2>
              <span className={styles.span__subtitleBadge}>
                ENO‘s Badges are NFTs that verify your <br />
                participation in an activity within our social ecosystem.
              </span>

            </div>
          </div>
        </section>
      )}

      {showSection === "nfts" && (
        /* ======= NFT's Section ======= */
        <section className={styles.badge__video}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={NFTBlanco}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                {/* <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} /> */}
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleNFT}>
                What are ENO NTFs?
              </h2>
              <span className={styles.span__subtitleBadge__alt}>
                Each NFT has a unique and interchangeable identity,<br />
                making them perfect for certifying the authenticity <br />
                and ownership of digital collectibles, art, and other unique items<br />
              </span>

            </div>
          </div>
        </section>
      )}

      {showSection === "nfts-alternative" && (
        /* ======= NFT's Section ======= */
        <section className={styles.badge__video__alt}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={badgesNFTs}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} />
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleNFT}>
                What are ENO NTFs?
              </h2>
              <span className={styles.span__subtitleBadge__alt}>
                Each NFT has a unique and interchangeable identity,<br />
                making them perfect for certifying the authenticity <br />
                and ownership of digital collectibles, art, and other unique items<br />
              </span>

            </div>
          </div>
        </section>
      )}

      {showSection === "nftsDetaiView" && (
        /* ======= NFT's Section ======= */
        <section className={styles.badge__video__altDetailView}>
          <div className={styles.badge__contents}>
            <div className={styles.badge___left}>
              <div className={styles.videoContainer}>
                <video
                  src={NFTBlanco}
                  type="video/mp4"
                  loop
                  muted
                  autoPlay
                  playsInline
                  className={styles.badgeLeft}
                />
                {/* <img src={BadgeCover} alt="Badge Cover" className={styles.badgeCover} /> */}
              </div>
            </div>
            <div className={styles.badge__right}>
              <h2 className={styles.section__titleNFT}>
                What are ENO NTFs?
              </h2>
              <span className={styles.span__subtitleBadge__alt}>
                Each NFT has a unique and interchangeable identity,<br />
                making them perfect for certifying the authenticity <br />
                and ownership of digital collectibles, art, and other unique items<br />
              </span>

            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Badges;
