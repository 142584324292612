import React, { useState } from 'react';
import './Accordion.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import NFTDetailView from '../components/NFTDetailView';

const FAQSAccordion = ({ showSection }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      {/* Blue light desktop & mobile */}
      {showSection === "accordeon-1" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText">
              <li className={activeIndex === 0 ? "showAnswer" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrow">
                  <span className="question">What is ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>
              <li className={activeIndex === 1 ? "showAnswer" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrow">
                  <span className="question">What is the ENO Launchpad?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrow">
                  <span className="question">What are ENO NFT Badges?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO Badges are digital medals that demonstrate your participation on the platform. Each badge represents your attendance and commitment to various activities and events sponsored by ENO. They are unique to you and protected by blockchain technology, meaning no one can take them away or counterfeit them.</p>
              </li>
              <li className={activeIndex === 3 ? "showAnswer" : ""} onClick={() => toggleFAQ(3)}>
                <div className="questionArrow">
                  <span className="question">How can I mint an NFT?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>"Mint NFT".</strong><br />
                  3. Choose the <strong>NFT</strong> you want to claim and click the <strong>"Mint"</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT's"</strong> to see all the NFT's you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Blue light desktop & White mobile */}
      {showSection === "accordeon-2" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-alt">
              <li className={activeIndex === 0 ? "showAnswer" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrow">
                  <span className="question-alt">What is ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrow">
                  <span className="question-alt">What is the ENO Launchpad?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>

              <li className={activeIndex === 2 ? "showAnswer" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrow">
                  <span className="question-alt">What are ENO Badges?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>
                  ENO NFTs are unique and collectible digital assets representing
                  various experiences, products, and services within the ENO ecosystem.
                  These NFTs can be acquired, sold, and traded on the ENO Marketplace,
                  providing additional value and utility to members of the ENO community.
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Blue light desktop & mobile */}
      {showSection === "accordeon-3" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText">
              <li className={activeIndex === 0 ? "showAnswer" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrow">
                  <span className="question">What is ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrow">
                  <span className="question">What are ENO NFT's?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO NFTs are unique and collectible digital assets representing various experiences, products, and services within the ENO ecosystem. These NFTs can be acquired, sold, and traded on the ENO Marketplace, providing additional value and utility to members of the ENO community.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrow">
                  <span className="question">How can I mint an ENO Badge?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT Badge in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>'Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/how-to-mint-an-eno-badge/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Blue light desktop & White Mobile */}
      {showSection === "accordeon-4" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-alt">
              <li className={activeIndex === 0 ? "showAnswer" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrow">
                  <span className="question-alt">What is ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswer" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrow">
                  <span className="question-alt">What are ENO Badges?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>ENO Badges are digital medals that demonstrate your participation on the platform. Each badge represents your attendance and commitment to various activities and events sponsored by ENO. They are unique to you and protected by blockchain technology, meaning no one can take them away or counterfeit them.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrow">
                  <span className="question-alt">How can I mint an ENO Badge?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT Badge in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>'Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* White desktop & White mobile */}
      {showSection === "accordeon-5" && (
        <div className="accordionWhite">
          <div className="accordionTextWhite">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText-altWhite">
              <li className={activeIndex === 0 ? "showAnswerWhite" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What is ENO?</span>
                  <div className="arrow-containerWhite">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>

              <li className={activeIndex === 1 ? "showAnswerWhite" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What is the ENO Launchpad?</span>
                  <div className="arrow-containerWhite">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>

              <li className={activeIndex === 2 ? "showAnswerWhite" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">What are ENO Badges?</span>
                  <div className="arrow-containerWhite">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>
                  ENO NFTs are unique and collectible digital assets representing
                  various experiences, products, and services within the ENO ecosystem.
                  These NFTs can be acquired, sold, and traded on the ENO Marketplace,
                  providing additional value and utility to members of the ENO community.
                </p>
              </li>

              <li className={activeIndex === 3 ? "showAnswerWhite" : ""} onClick={() => toggleFAQ(3)}>
                <div className="questionArrowWhite">
                  <span className="question-altWhite">How can I mint an NFT?</span>
                  <div className="arrow-containerWhite">
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronUp : faChevronUp} className="arrowWhite" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. <strong>Select 'NFT Badges'.</strong><br />
                  3. Choose the badge you want to claim and click the <strong>'Mint'</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT Badges'</strong> to see all the badges you have claimed.<br />
                  If you need more details or additional information on any of these puntos, click here: <a href='https://blog.eno.network/' className='links__accordionWhite'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      {showSection === "accordeon-6" && (
        <div className="accordion">
          <div className="accordionText">
            <h3>Frequently Asked Questions</h3>
            <ul className="faqText">
              <li className={activeIndex === 0 ? "showAnswer" : ""} onClick={() => toggleFAQ(0)}>
                <div className="questionArrow">
                  <span className="question">What is ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 0 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO is a decentralized ecosystem designed to revolutionize the way communities are formed, managed, and interacted with using blockchain technology. ENO provides tools and infrastructure for launching and managing social clubs in Web3.0, facilitating a new era of community building that is decentralized, transparent, and engaging.</p>
              </li>
              <li className={activeIndex === 1 ? "showAnswer" : ""} onClick={() => toggleFAQ(1)}>
                <div className="questionArrow">
                  <span className="question">What is the ENO Launchpad?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 1 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>The ENO Launchpad is an innovative platform designed to empower the Web3 community, creators, innovators, and project developers by providing tools for staking, launching new digital assets, and minting non-fungible tokens (NFTs). The Launchpad simplifies the process of adopting, managing, and distributing digital assets while ensuring high standards of quality and security.</p>
              </li>
              <li className={activeIndex === 2 ? "showAnswer" : ""} onClick={() => toggleFAQ(2)}>
                <div className="questionArrow">
                  <span className="question">What are ENO NFT Badges?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 2 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>ENO Badges are digital medals that demonstrate your participation on the platform. Each badge represents your attendance and commitment to various activities and events sponsored by ENO. They are unique to you and protected by blockchain technology, meaning no one can take them away or counterfeit them.</p>
              </li>
              <li className={activeIndex === 3 ? "showAnswer" : ""} onClick={() => toggleFAQ(3)}>
                <div className="questionArrow">
                  <span className="question">How can I mint an NFT?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 3 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To mint an NFT in the ENO ecosystem, you need a Metamask wallet configured with the Arbitrum ONE network and Ethereum, along with a small amount of ETH for gas fees. The steps include:<br />
                  1. In the Metamask browser, enter the address enotoken.io to access the ENO website.<br />
                  2. Select <strong>"Mint NFT".</strong><br />
                  3. Choose the <strong>NFT</strong> you want to claim and click the <strong>"Mint"</strong> button.<br />
                  4. Connect your wallet.<br />
                  5. Confirm the transaction.<br />
                  6. Go to <strong>'My NFT's"</strong> to see all the NFT's you have claimed.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
              <li className={activeIndex === 4 ? "showAnswer" : ""} onClick={() => toggleFAQ(4)}>
                <div className="questionArrow">
                  <span className="question">What is staking?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 4 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>Staking in the ENO ecosystem is a mechanism that allows users to earn passive growth by locking their ENO tokens for a specific period. By staking, users contribute to the stability of the ecosystem while receiving rewards in the form of additional ENO tokens.</p>
              </li>

              <li className={activeIndex === 5 ? "showAnswer" : ""} onClick={() => toggleFAQ(5)}>
                <div className="questionArrow">
                  <span className="question">How to stake ENO?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 5 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To stake your ENO tokens, follow these steps:<br />
                  1. Access the ENO platform:<br />
                  • On your device, open Metamask and ensure you are connected to the Arbitrum ONE network. <br />
                  • Go to <a href='https://app.eno.network/bridge' className='links__accordion'> Staking </a><br />
                  2. Navigate to the staking section.<br />
                  3. Click on <strong>"Stake"</strong>.<br />
                  4. Enter the amount of ENO tokens you wish to lock and confirm.<br />
                  5. Set the spending limit in Metamask and approve<br />
                  6. Enjoy the rewards generated by your participation in staking<br />
                  7. You can withdraw the rewards or stake them to generate more.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>

              <li className={activeIndex === 6 ? "showAnswer" : ""} onClick={() => toggleFAQ(6)}>
                <div className="questionArrow">
                  <span className="question">What is the ENO token?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 6 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>The ENO token is the native token of ENO, used to facilitate various interactions within the decentralized environment. These tokens act as a medium of exchange, incentivize user participation, and enhance access to the range of experiences within the ENO ecosystem.</p>
              </li>

              <li className={activeIndex === 7 ? "showAnswer" : ""} onClick={() => toggleFAQ(7)}>
                <div className="questionArrow">
                  <span className="question">How to buy/obtain the ENO token?</span>
                  <div className="arrow-container">
                    <FontAwesomeIcon icon={activeIndex === 7 ? faChevronDown : faChevronDown} className="arrow" />
                  </div>
                </div>
                <p>To buy or obtain ENO tokens, you can follow these steps:<br />
                  1. Set up a compatible wallet (such as Metamask) and connect it to the Arbitrum One network.<br />
                  2. Acquire ETH or USDT through a cryptocurrency exchange and transfer them to your wallet.<br />
                  3. In the <strong>"Swap"</strong> section, ensure that ETH is selected in the <strong>"From"</strong> field.<br />
                  4. In the <strong>"To"</strong> field, search for ENO Token. If not found, enter the token contract address obtained from a reliable source.<br />
                  5. Enter the amount of ETH you wish to exchange for ENO Token.<br />
                  6. Uniswap will automatically show the amount of ENO Tokens you will receive based on the current exchange rate.<br />
                  7. Review the transaction information and click <strong>"Swap"</strong>.<br />
                  8. Confirm the transaction in your wallet, review gas fees, and adjust if necessary.<br />
                  If you need more details or additional information on any of these points, click here: <a href='https://blog.eno.network/' className='links__accordion'> Blog Link.</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default FAQSAccordion;
