import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";

import ContractABI from "../ABIs/SenderETHABI.json";
import ContractABI2 from "../ABIs/SenderABI.json";
import ERC20ABI from "../ABIs/ERC20ABI.json";
import logotipo from "../assets/ENOLogo.svg";

import { useMetaMaskConnection } from "./MetaMaskConnection";
import './BridgeComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faXTwitter, faInstagram, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { useToast } from "@chakra-ui/react";

function BridgeComponent() {
  const { provider, account, connectWalletHandler } = useMetaMaskConnection();
  const [minEth, setMinEth] = useState();
  const [tokenAmount, setTokenAmount] = useState('');
  const [senderAddress, setSenderAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [destinationChainSelector, setDestinationChainSelector] = useState("");
  const [receiverAddress, setReceiverAddress] = useState('');
  const [originChain, setOriginChain] = useState();
  const [destinationChain, setDestinationChain] = useState();
  const [currentChainId, setCurrentChainId] = useState('0');
  const [transactionDetails, setTransactionDetails] = useState(null);
  const toast = useToast();
  const [userTokenBalance, setUserTokenBalance] = useState('');

  const chains = [
    { id: "0", name: "Ethereum" },
    { id: "3", name: "Arbitrum" },
  ];

  useEffect(() => {
    const getChainId = async () => {
      if (provider) {
        const network = await provider.getNetwork();
        setCurrentChainId(network.chainId.toString());
        switch (network.chainId) {
          case 1: // Ethereum
            setOriginChain("0");
            setDestinationChain("3");
            setSenderAddress("0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F");
            setTokenAddress("0x1C3d163219Bb74f430411b95D66b72056f366eC1");
            break;
          case 42161: // Arbitrum
            setOriginChain("3");
            setDestinationChain("0");
            setSenderAddress("0x521EF110851F00795b5840Ab56eDc2AF0342f16f");
            setTokenAddress("0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F");
            break;
          default:
            setOriginChain("");
            setDestinationChain("");
        }
      }
    };
    getChainId();
  }, [provider]);

  useEffect(() => {
    const updateDestinationSettings = (selectedDestinationChain) => {
      switch (selectedDestinationChain) {
        case "0":
          setDestinationChainSelector("5009297550715157269");
          setReceiverAddress("");
          break;
        case "3":
          setDestinationChainSelector("4949039107694359620");
          setReceiverAddress("");
          break;
        default:
          setDestinationChainSelector("");
          setReceiverAddress("");
      }
    };
    updateDestinationSettings(destinationChain);
  }, [destinationChain]);

  const filteredChainsForOrigin = chains.filter(chain => {
    if (currentChainId === '1' && chain.name === 'Ethereum') return true;
    if (currentChainId === '42161' && chain.name === 'Arbitrum') return true;
    return false;
  });
  const filteredChainsForDestination = chains.filter(chain => chain.id !== originChain);

  const getMinEthRequired = useCallback(async () => {
    if (provider && senderAddress) {
      const abi = currentChainId !== '1' ? ContractABI2 : ContractABI;
      const bridgeContract = new ethers.Contract(
        senderAddress,
        abi,
        provider.getSigner()
      );
      const minEthRequired = await bridgeContract.minEthRequired();
      setMinEth(ethers.utils.formatEther(minEthRequired));
    }
  }, [provider, senderAddress, currentChainId]);

  const lockTokensAndEth = async () => {
    if (provider && senderAddress) {
      const abi = currentChainId !== '1' ? ContractABI2 : ContractABI;
      const bridgeContract = new ethers.Contract(
        senderAddress,
        abi,
        provider.getSigner()
      );
      try {
        await checkTokenBalanceAndAllowance();

        await approveTokens();
         ("Tokens approved for bridging");
         ("destinationChain:", destinationChain);
         ("destinationChainSelector:", destinationChainSelector);
         ("receiverAddress:", receiverAddress);
         ("account:", account);
         ("tokenAmount:", tokenAmount);
         ("minEth:", minEth);

        if (destinationChain === "0") {
           ("Calling unlockWithMinEth");
          const tx = await bridgeContract.unlockWithMinEth(
            destinationChainSelector,
            receiverAddress,
            account,
            ethers.utils.parseUnits(tokenAmount, "ether"),
            0,
            { value: ethers.utils.parseUnits(minEth, "ether") }
          );
          await tx.wait();
          toast({
            title: 'Tokens bridged',
            description: 'Tokens bridged successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
           ("Tokens bridged");
           (tx);
          setTransactionDetails(tx);
        } else {
           ("Calling mintWithMinEth");
          const tx = await bridgeContract.mintWithMinEth(
            destinationChainSelector,
            receiverAddress,
            account,
            ethers.utils.parseUnits(tokenAmount, "ether"),
            0,
            { value: ethers.utils.parseUnits(minEth, "ether") }
          );
          await tx.wait();
          toast({
            title: 'Tokens bridged',
            description: 'Tokens bridged successfully',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
           ("Tokens bridged");
           (tx);
          setTransactionDetails(tx);
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        console.error("Error bridged tokens:", error);
      }
    }
  };

  const checkTokenBalanceAndAllowance = async () => {
    const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider.getSigner());
    const balance = await tokenContract.balanceOf(account);
    const allowance = await tokenContract.allowance(account, senderAddress);
    const amountToTransfer = ethers.utils.parseUnits(tokenAmount, "ether");

     (`Token balance is: ${ethers.utils.formatEther(balance)} tokens`);
     (`Current allowance is: ${ethers.utils.formatEther(allowance)} tokens`);
     (`Attempted transfer amount is: ${ethers.utils.formatEther(amountToTransfer)} tokens`);

    if (balance.lt(amountToTransfer)) {
      toast({
        title: 'ERROR',
        description: 'Transfer amount exceeds your token balance.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      throw new Error("Insufficient token balance for transfer.");
    }

    if (allowance.lt(amountToTransfer)) {
       ("Insufficient allowance for transfer. Approving tokens...");
      await approveTokens();
    } else {
       ("Sufficient allowance for transfer. No need to approve more tokens.");
    }
  };

  const approveTokens = async () => {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider.getSigner());
      const currentAllowance = await tokenContract.allowance(account, senderAddress);
      const amountToApprove = ethers.utils.parseUnits(tokenAmount, "ether");

      if (currentAllowance.lt(amountToApprove)) {
         (`Current allowance is ${ethers.utils.formatEther(currentAllowance)}. Approving more tokens...`);
        const tx = await tokenContract.approve(senderAddress, amountToApprove);
        await tx.wait();
         ("Tokens approved for transfer.");
      } else {
         ("Sufficient allowance already granted. No need to approve more tokens.");
      }
    } catch (error) {
      toast({
        title: 'ERROR',
        description: 'Transfer amount exceeds your token balance.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      console.error("Error approving tokens:", error);
      throw error;
    }
  };

  useEffect(() => {
    getMinEthRequired();
  }, [getMinEthRequired]);

  const formatAccount = (account) => {
    if (typeof account === 'string' && account.length > 8) {
      const firstPart = account.substring(0, 4);
      const lastPart = account.substring(account.length - 4);
      return `${firstPart}...${lastPart}`;
    }
    return account;
  };

  const getUserTokenBalance = useCallback(async () => {
    if (account && tokenAddress && provider) {
      const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider);
      const balance = await tokenContract.balanceOf(account);
      const formattedBalance = ethers.utils.formatUnits(balance, 'ether');
      setUserTokenBalance(formattedBalance);
    }
  }, [account, tokenAddress, provider]);

  const setMaxTokenAmount = () => {
    setTokenAmount(userTokenBalance);
    setReceiverAddress(userTokenBalance); // Sincroniza el valor con el campo de destino
  };

  useEffect(() => {
    getUserTokenBalance();
  }, [getUserTokenBalance]);

  // Función para manejar la entrada numérica, permitiendo números, puntos y comas
  const handleInputChange = (e) => {
    let value = e.target.value;
  
    // Eliminar cualquier carácter que no sea un número o un punto
    value = value.replace(/[^0-9.]/g, '');
  
    // Permitir solo un punto decimal
    const countDots = (value.match(/\./g) || []).length;
    if (countDots > 1) {
      // Si hay más de un punto, eliminar el último punto agregado
      const lastDotIndex = value.lastIndexOf('.');
      value = value.slice(0, lastDotIndex) + value.slice(lastDotIndex + 1);
    }
  
    // Evitar que el valor comience con un punto decimal
    if (value.startsWith('.')) {
      value = '';
    }
  
    // Actualizar los valores
    setTokenAmount(value);
    setReceiverAddress(value);
  };  
  

  if (currentChainId !== '1' || !account) {
    return (
      <div className="network-warning">
        <button className="bridge__btn-bridge color-1" onClick={connectWalletHandler}>Connect Wallet</button>
        <p>Please switch to the Ethereum network <br />in order to use the bridge.</p>
      </div>
    );
  } else {
    return (
      <>
        <header className="app__header">
          <div className="left__menu">
            <a href="https://eno.network/">
            </a>
          </div>
        </header>

        <div className="containerBridge">
          <div className="titleBridge"><h2>ENO Bridge</h2></div>
          <div className="mainContainer">

            <div className="sourceChainContainer" id="sourceChainContainer">
              <div className="horizontal-layout">
                <label htmlFor="sourceChain" className="contentLabel">Source Chain</label>
                <select
                  value={originChain}
                  onChange={(e) => setOriginChain(e.target.value)}
                  className="selectBridge">
                  {filteredChainsForOrigin.map(chain => (
                    <option key={chain.id} value={chain.id}>{chain.name}</option>
                  ))}
                </select>
              </div>
              <div className="bridgeForm">
                <div className="inputSource">
                  <input
                    type="text"
                    value={tokenAmount}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    className="inputAmount"
                  />
                  <button className="max" onClick={setMaxTokenAmount}>MAX</button>
                </div>
              </div>
              <div className="balance-max-container">
                <p>{userTokenBalance} ENO Tokens</p>
              </div>
            </div>

            <div className="destinationChainContainer" id="destinationChainContainer">
              <div className="horizontal-layout">
                <label htmlFor="destinationChain" className="contentLabel">Destination Chain</label>
                <select
                  value={destinationChain}
                  onChange={(e) => setDestinationChain(e.target.value)}
                  className="selectBridge">
                  {filteredChainsForDestination.map(chain => (
                    <option key={chain.id} value={chain.id}>{chain.name}</option>
                  ))}
                </select>
              </div>
              <div className="bridgeForm">
                <div className="inputDestination">
                  <input
                    type="text"
                    value={receiverAddress}
                    onChange={handleInputChange}
                    placeholder="Enter amount"
                    className="inputAmount"
                  />
                </div>
              </div>
              <div className="balance-max-container">
                <p>{userTokenBalance} ENO Tokens</p>
              </div>
            </div>
          </div>
          <button onClick={lockTokensAndEth} className="bridge__btn color-1">Bridge ENO Tokens</button>
          {transactionDetails && (
            <div>
              <p className="white">Bridging Tokens: <a href={`https://ccip.chain.link/tx/${transactionDetails.hash}`} rel="noopener noreferrer">(Click here to view bridge transaction status)</a></p>
              <p className="white">Add the Arbitrum ENO token to your Wallet:</p>
              <p className="white">0x2b41806CBf1FFB3D9e31A9ECE6B738Bf9D6f645F</p>
              <p className="white">Click here to see the guide</p>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default BridgeComponent;
