import React, { useEffect, useState } from 'react';
import { useNetworkSwitcher, chain } from '../hooks/useNetworkSwitcher';
import useMetaMaskConnector from '../hooks/useMetaMaskConnector';
import { useToast } from '@chakra-ui/react';
import { getPoolDetails, action, autoCompound, withdrawToken } from '../config_staking/config';
import styles from './Staking.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// components
import LatestPost from "../Templates/LatestPosts";
import Newsletter from "../Templates/Newsletter";
import EnoBadges from "../Templates/BadgesNewsletter";
import Accordion from "../Templates/Accordion";

function Staking() {
  const { currentNetwork, changeNetwork } = useNetworkSwitcher();
  const { isConnected, connectMetaMask } = useMetaMaskConnector();
  const toast = useToast();
  const [poolArray, setPoolArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: '',
    index: null,
  });

  useEffect(() => {
    const fetchPoolDetails = async () => {
      if (isConnected && currentNetwork === chain) {
        setIsLoading(true);
        try {
          const details = await getPoolDetails();
          setPoolArray(details || []);
        } catch (err) {
          console.error('Error fetching pool details:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchPoolDetails();
  }, [isConnected, currentNetwork]);

  useEffect(() => {
    const updatePoolDetails = async () => {
      if (isConnected && currentNetwork === chain) {
        const updatedPoolArray = await getPoolDetails();
        setPoolArray(updatedPoolArray || []);
      }
    };

    const intervalId = setInterval(updatePoolDetails, 5000);
    return () => clearInterval(intervalId);
  }, [isConnected, currentNetwork]);

  useEffect(() => {
    if (modalState.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modalState.isOpen]);

  const openModal = (i, action) => {
    setModalState({
      isOpen: true,
      action,
      index: i,
    });
  };

  const closeModal = () => {
    setModalState({
      ...modalState,
      isOpen: false,
    });
  };

  const stakeTokens = async () => {
    setIsLoading(true);
    try {
      const amount = document.getElementById(`amtModalStake${modalState.index}`).value;
      await action(modalState.index, amount, poolArray[modalState.index].tokenaddr, 'stake');
    } catch (error) {
      console.error('Error staking tokens:', error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const unstakeTokens = async () => {
    setIsLoading(true);
    try {
      const amount = document.getElementById(`amtModalUnstake${modalState.index}`).value;
      await action(modalState.index, amount, poolArray[modalState.index].tokenaddr, 'unstake');
    } catch (error) {
      console.error('Error unstaking tokens:', error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const autoCompound2 = async (i) => {
    setIsLoading(true);
    try {
      await autoCompound(i);
    } catch (error) {
      console.error('Error during auto-compound:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const withdrawToken2 = async (i) => {
    setIsLoading(true);
    try {
      await withdrawToken(i);
    } catch (error) {
      console.error('Error withdrawing tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnect = async () => {
    try {
      await connectMetaMask();
      await changeNetwork();
    } catch (error) {
      console.error('Failed to connect MetaMask and switch network:', error);
    }
  };

  const renderConnectMessage = () => {
    if (!isConnected && currentNetwork !== chain) {
      return 'Please connect your wallet and switch to the correct network.';
    } else if (!isConnected) {
      return 'Please connect your wallet.';
    } else if (currentNetwork !== chain) {
      return 'Please switch to the correct network.';
    }
    return '';
  };

  const renderConnectButtonLabel = () => {
    if (!isConnected) {
      return 'Connect Wallet';
    } else if (currentNetwork !== chain) {
      return 'Change Network';
    }
    return '';
  };

  const isMetaMaskInstalled = () => {
    return typeof window.ethereum !== 'undefined' || typeof window.web3 !== 'undefined';
  };

  if (!isMetaMaskInstalled()) {
    return (
      <div className="install-metamask-container">
        <button as="a" href="https://metamask.io/download.html" colorScheme="teal" size="lg" className='connect__container-install'>
          Install Wallet
        </button>
        <p className="install-message">Please install a web3 compatible wallet to proceed.</p>
      </div>
    );
  }

  if (!isConnected || currentNetwork !== chain) {
    return (
      <div className="connect-container">
        <button onClick={handleConnect} colorScheme="teal" size="lg" className='connect-container-btn'>
          {renderConnectButtonLabel()}
        </button>
        <p className="connect-message">{renderConnectMessage()}</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {poolArray.map((poolData, i) => (
        poolData && (
          <React.Fragment key={i}>
            <div className={styles.stakingCard}>
              <div className={styles.mainContainer}>
                <div className={styles.gridContainer}>
                  <div className={styles.column1}>
                    <h6>APR</h6>
                    <h5>{poolData.apr}%</h5>
                  </div>
                  <div className={styles.column2}>
                    <h6>My Stakings</h6>
                    <h5>{poolData.userstaked}</h5>
                  </div>
                  <div className={styles.column3}>
                    <h6>Pending Rewards</h6>
                    <h5>{poolData.reward} ENO</h5>
                  </div>
                  <div className={styles.column4}>
                    <h6>Total Staked In Pool</h6>
                    <h5>{poolData.totalstaked}</h5>
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.successBtn} onClick={() => openModal(i, 'stake')}>Stake</button>
                <button className={styles.dangerBtn} onClick={() => openModal(i, 'unstake')}>Unstake</button>
                <button className={styles.warningBtn} onClick={() => withdrawToken2(i)}>Withdraw</button>
                <button className={styles.infoBtn} onClick={() => autoCompound2(i)}>Compound</button>
              </div>
            </div>
            <Accordion showSection="accordeon-6" />
            <LatestPost showSection="lastpost-2" />
            <Newsletter />
          </React.Fragment>
        )
      ))}

      {modalState.isOpen && (
        <>
          <div className={styles.modalOverlay} onClick={closeModal}></div>
          <div className={styles.modalFadeShow}>
            <div className="modal-dialog modal-dialog-centered">
              <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                  <button type="button" className={styles.closeButton} onClick={closeModal}>
                    <FontAwesomeIcon icon={faChevronLeft} className={styles.closeIconChevron} />
                  </button>
                </div>
                <div className={styles.modalBody}>
                  <div className={styles.inputGroup}>
                  <input
                    className={styles.inputContent}
                    type="text"
                    id={modalState.action === 'stake' ? `amtModalStake${modalState.index}` : `amtModalUnstake${modalState.index}`}
                    placeholder="Input Amount"
                    onInput={(e) => {
                      let value = e.target.value;

                      // Eliminar cualquier carácter que no sea un número o un punto
                      value = value.replace(/[^0-9.]/g, '');

                      // Evitar que el valor comience con un punto
                      if (value.startsWith('.')) {
                        value = '';
                      }

                      // Evitar más de un punto decimal
                      const countDots = (value.match(/\./g) || []).length;
                      if (countDots > 1) {
                        // Si hay más de un punto, eliminar el último punto agregado
                        const lastDotIndex = value.lastIndexOf('.');
                        value = value.slice(0, lastDotIndex) + value.slice(lastDotIndex + 1);
                      }

                      // Actualizar el valor del input
                      e.target.value = value;
                    }}
                  />
                    <button className={styles.maxBtn} type="button">MAX</button>
                  </div>
                </div>
                <div className={styles.modalFooter}>
                  <button className={styles.confirmBtn} onClick={modalState.action === 'stake' ? stakeTokens : unstakeTokens}>
                    {modalState.action === 'stake' ? 'Confirm Stake' : 'Confirm Unstake'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Staking;