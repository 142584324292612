import { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import { useToast } from "@chakra-ui/react";

export function useMetaMaskConnection() {
  const [provider, setProvider] = useState(null);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [account, setAccount] = useState(null);
  const toast = useToast();

  // Añadimos el ID de la red de Ethereum mainnet
  const ethereumChainId = '0x1'; // ID de la cadena para Ethereum Mainnet

  // Definimos verifyAndSwitchToEthereumNetwork con useCallback para evitar redefiniciones innecesarias
  const verifyAndSwitchToEthereumNetwork = useCallback(async () => {
    try {
      if (window.ethereum.networkVersion !== ethereumChainId) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ethereumChainId }],
        });
      }
    } catch (switchError) {
      // Manejamos el error en caso de que el usuario rechace el cambio de red
      if (switchError.code === 4001) {
        toast({
          title: 'Network change rejected',
          description: 'Please switch to the Ethereum Network.',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
      console.error("Error switching to Ethereum network:", switchError);
    }
  }, [toast, ethereumChainId]);

  const reconnectWalletOnLoad = useCallback(async () => {
    if (window.ethereum) {
      try {
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(web3Provider);

        const signer = web3Provider.getSigner();
        const accounts = await signer.getAddress();
        setAccount(accounts);

        // Verificamos y solicitamos el cambio de red si es necesario
        await verifyAndSwitchToEthereumNetwork();
      } catch (error) {
        console.error("Error reconnecting to MetaMask:", error);
      }
    }
  }, [verifyAndSwitchToEthereumNetwork]);

  useEffect(() => {
    if (account) {
      reconnectWalletOnLoad();
    }
  }, [account, reconnectWalletOnLoad]);

  const connectWalletHandler = async () => {
    if (isMetamaskInstalled) {
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setAccount(accounts[0]);
        // Verificamos y solicitamos el cambio de red después de conectar la cartera
        await verifyAndSwitchToEthereumNetwork();
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
      const checkIfWalletIsConnected = async () => {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            reconnectWalletOnLoad();
          }
        } catch (error) {
          console.error("Error checking for connected accounts:", error);
        }
      };

      checkIfWalletIsConnected();
    }
  }, [reconnectWalletOnLoad]);

  useEffect(() => {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          setAccount(null);
        }
      };

      window.ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      };
    }
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const handleChainChanged = () => {
        window.location.reload();
      };

      window.ethereum.on('chainChanged', handleChainChanged);

      return () => {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      };
    }
  }, []);

  return {
    provider,
    isMetamaskInstalled,
    account,
    connectWalletHandler,
  };
}
